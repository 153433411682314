const scannerRoutes = [
    {
        path: "/test/scanner",
        name: "testScanner",
        component: () => import("@/modules/scanner/entry/TestNewScanner.vue"),
        meta: {
            requireAuth: true,
            subject: "scan",
            action: "sale",
            layout: "full"
        }
    },
    {
        path: "/sale/scanner",
        name: "saleScanner",
        component: () => import("@/modules/scanner/entry/New.vue"),
        meta: {
            requireAuth: true,
            subject: "scan",
            action: "sale",
            layout: "full"
        }
    },
    {
        path: "/sale/issue-form/:customer_id",
        name: "saleIssueForm",
        component: () => import("@/modules/scanner/entry/IssueForm.vue"),
        meta: {
            requireAuth: true,
            subject: "scan",
            action: "sale",
            layout: "full"
        }
    },
    {
        path: "/redeem/scanner",
        name: "redeemScanner",
        component: () => import("@/modules/scanner/entry/New.vue"),
        meta: {
            requireAuth: true,
            subject: "scan",
            action: "redeem",
            layout: "full"
        }
    },
    {
        path: "/issue-point/scanner",
        name: "issuePointScanner",
        component: () => import("@/modules/scanner/entry/New.vue"),
        meta: {
            requireAuth: true,
            subject: "coins",
            action: "issue",
            layout: "full"
        }
    },
    {
        path: "/redeem-point/scanner",
        name: "redeemPointScanner",
        component: () => import("@/modules/scanner/entry/New.vue"),
        meta: {
            requireAuth: true,
            subject: "with-coins",
            action: "pay",
            layout: "full"
        }
    },
    {
        path: "/issue-reward/scanner",
        name: "issueRewardScanner",
        component: () => import("@/modules/scanner/entry/New.vue"),
        meta: {
            requireAuth: true,
            subject: "rewards",
            action: "issue",
            layout: "full"
        }
    },
    {
        path: "/issue-point/manage",
        name: "issuePointForm",
        component: () => import("@/modules/scanner/entry/IssuePointForm.vue"),
        meta: {
            requireAuth: true,
            subject: "coins",
            action: "issue",
            layout: "full"
        }
    },
    {
        path: "/redeem-point/manage",
        name: "redeemPointForm",
        component: () => import("@/modules/scanner/entry/RedeemPointForm.vue"),
        meta: {
            requireAuth: true,
            subject: "with-coins",
            action: "pay",
            layout: "full"
        }
    },
    {
        path: "/campaign/scanner",
        name: "campaignScanner",
        component: () => import("@/modules/scanner/entry/New.vue"),
        meta: {
            requireAuth: true,
            layout: "full"
        }
    },
    {
        /**
         * Remove permission since the bottom menu tag contains three permissions.
         * $can('manage', 'customer') ||
         * $can('manage', 'membership') ||
         * $can('issue', 'coins')

            subject: "customer",
            action: "manage",
         **/
        path: "/customer/scanner",
        name: "customerScanner",
        component: () => import("@/modules/scanner/entry/New.vue"),
        meta: {
            requireAuth: true,
            subject: "",
            action: "",
            layout: "full"
        }
    }
]

export default scannerRoutes
